export const empty = (value: any) => {
  const result = value === undefined || value === null || value === '';

  return result;
};

export const twoWords = (value: any) => {
  return value.split(' ').filter(s => s).length > 1;
};

export const requireNonWhitespace = (value: any) => {
  if (empty(value?.trim())) {
    return false;
  }

  return true;
};

export const requireNonUndefined = (value: any) => {
  if (empty(value) && value !== undefined) {
    return false;
  }

  return true;
};

export const requireWithDefault = (defaultValue: any) => (value: any) => {
  if (empty(value) || value === defaultValue) {
    return false;
  }

  return true;
};

export const sameAsWithConvert = (prop: string, func: Function = (val: any) => val) => (
  value: any,
  model: any,
) => func(value) === func(model[prop]);

export const byRegex = (regex: RegExp, invert: boolean = false) => {
  return (value: any) => {
    if (empty(value)) {
      return true;
    }

    let result = regex.test(value);

    if (invert) {
      result = !result;
    }

    return result;
  };
};

export const dangerous = byRegex(/[<>]/, true);
export const symbolsNotAllowed = byRegex(/.*[#^*?'].*/, true);

export const nameComplex = (value: string) => {
  const notEmpty = byRegex(/.*[^ ].*/, false);
  const name = byRegex(
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,
  );
  const notOnlySymbol = byRegex(/^[,.'"-]+$/, true)

  return notEmpty(value) && name(value) && notOnlySymbol(value);
};
export const zipCode = byRegex(/(^\d{5}$)|(^\d{9}$)/);
export const phoneCode = byRegex(/^\+?[0-9]{1,3}$/);
export const phoneAndDashed = byRegex(/^(\d{8,10}|(\d{3}-\d{3}-\d{4}))$/);
export const phoneWithoutCode = byRegex(/^\d{8,10}$/);
export const phoneWithCode = byRegex(/^\+?[0-9]{9,13}$/);
export const booking = byRegex(/^\d{4,6}$/);
export const IATAN = byRegex(/^\d{3,32}$/);

export const timeRegex = (value) => {
  if (empty(value)) {
    return true;
  }

  return (
    /^(0?[1-9]|1[012]):([0-5][0-9]) [PpAa]{1}[Mm]{1}$/.test(value) ||
    /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
  );
};

export const separatedEmails = (value: string) => {
  const emailRegex = byRegex(
    /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/,
  );
  const splitedEmails = value
    .trim()
    .replace(/\s/g, '')
    .split(',');
  const validatedEmails = splitedEmails.filter((item) => emailRegex(item));
  return splitedEmails.length === validatedEmails.length && validatedEmails.length < 4;
};
