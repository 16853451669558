import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import TextRow from '@/forms/FormElements/Rows/TextRow/TextRow.vue';

import RowValidationMixin from '@/forms/FormElements/Mixins/RowValidationMixin';

import { names } from './EmailRow.validation';

@Component({ components: { TextRow } })
export default class EmailRow extends mixins(RowValidationMixin) {
  @Prop({ type: Boolean, default: false })
  readonly confirmation!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly fullWidth!: boolean;

  @Prop({ type: Number, default: 64 })
  readonly maxLength!: number;

  @Prop({ type: String, default: 'Enter your email address' })
  readonly placeholder!: string;

  @Prop({ type: String, default: 'Email Address' })
  readonly label!: string;

  @Prop({ type: [String, Number], default: '' })
  readonly idPrefix!: string;

  @Prop({ type: [String], default: '' })
  readonly className!: string;

  get classList() {
    let emailClassListBase = this.fullWidth ? 'lex-col--size-12 ' : 'lex-col--size-9 ';

    return (
      emailClassListBase +
      (this.confirmation === true
        ? 'lex-col-md--size-6'
        : this.fullWidth
        ? ''
        : 'lex-col-md--size-8')
    );
  }

  names = names;
}
