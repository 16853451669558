import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import RowValidationMixin from '@/forms/FormElements/Mixins/RowValidationMixin';
import CheckBoxRow from '@/forms/FormElements/Rows/CheckBoxRow/CheckBoxRow.vue';

import { names } from './TermsAndConditionsRow.validation';

@Component({ components: { CheckBoxRow } })
export default class TermsAndConditionsRow extends mixins(RowValidationMixin) {
  names = names;
  @Prop()
  TermsLink!: string;
  @Prop()
  PrivacyPolicyLink!: string;
  @Prop()
  isAccepted?: Boolean;

  get hasTextSlot() {
    return !!this.$slots.text
  }
}
