import { required } from 'vuelidate/lib/validators';
import { nameComplex } from '@/forms/Validations/CommonRules';

import BaseValidation from '@/forms/Validations/BaseValidation';

import { names } from '@/common/interfaces/dto/Person';
export { names } from '@/common/interfaces/dto/Person';

interface NameParams {
  isTitleRequired?: boolean;
  removeTitle?: boolean;
  stripRequired?: boolean;
}

export default (path: string = '', params?: NameParams) => {
  const rules = [];

  if (params?.removeTitle !== true) {
    rules.push({
      name: names.Title,
      rules: params?.isTitleRequired && !params?.stripRequired ? { required } : {},
      messages: {
        required: 'Choose a title',
      },
    });
  }

  rules.push({
    name: names.FirstName,
    rules: {
      ...(!params?.stripRequired ? { required } : {}),
      nameComplex,
    },
    messages: {
      required: 'Enter a first name',
      nameComplex: 'Enter a valid first name',
    },
  });

  rules.push({
    name: names.LastName,
    rules: {
      ...(!params?.stripRequired ? { required } : {}),
      nameComplex,
    },
    messages: {
      required: 'Enter a last name',
      nameComplex: 'Enter a valid last name',
    },
  });

  const result = new BaseValidation(rules, path);

  return result;
};
