import { requireWithDefault } from '@/forms/Validations/CommonRules';

import BaseValidation from '@/forms/Validations/BaseValidation';

export const names = {
  CheckTC: 'CheckTC',
};

export interface TCModel {
  CheckTC: boolean;
}

export default (path: string = '') =>
  new BaseValidation(
    [
      {
        name: names.CheckTC,
        rules: { required: requireWithDefault(false) },
        messages: {
          required: 'Please check T&C',
        },
        modelValue: false,
      },
    ],
    path,
  );
