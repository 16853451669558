import Person, { names as personNames } from '@/common/interfaces/dto/Person';

export interface EmailSignUpUser {
  Person?: Person;
  Email: string;
  AnalyticsPayload?: string;
}

const formNames = {
  UserPerson: 'Person',
  UserEmail: 'Email',
  TermsAndConditions: 'TermsAndConditions',
};

export const names = {
  ...personNames,
  ...formNames,
};
