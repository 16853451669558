import ApiService from '@/common/ApiService/ApiService';
import { HttpVerb } from '@/common/ApiService/HttpVerb';
import { email } from 'vuelidate/lib/validators';

const commonService = new ApiService('/api/CommonService/', {
  returnData: true,
});

export const isInBlackList = (value: string) => {
  if (!value || !(email as any)(value)) return true;

  return commonService.call(
    `CheckInBlackListEmail`,
    { email: value },
    { handleErrors: false, showLoading: false, cancel: true, verb: HttpVerb.GET },
  );
};
