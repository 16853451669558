import { required, email, sameAs } from 'vuelidate/lib/validators';

import BaseValidation from '@/forms/Validations/BaseValidation';
import ValidationRule from '@/forms/Validations/ValidationRule';

import { names } from '@/common/interfaces/dto/Person';
import { separatedEmails } from '@/forms/Validations/CommonRules';
import { isInBlackList } from '@/forms/Validations/AsyncRules';

export { names } from '@/common/interfaces/dto/Person';

interface EmailParams {
  name?: string;
  addConfirmation?: boolean;
  notRequired?: boolean;
  commaSeparatedEmails?: boolean;
  addBlackListValidation?: boolean;
}

export default (path: string = '', rowParams?: EmailParams) => {
  const emailRequredRules = rowParams?.notRequired ? {} : { required };
  const emailValidationRules = rowParams?.commaSeparatedEmails ? separatedEmails : email;
  const blackList = rowParams?.addBlackListValidation ? { isInBlackList } : {};

  const rules: ValidationRule[] = [
    {
      name: rowParams?.name || names.Email,
      rules: { ...emailRequredRules, emailValidationRules, ...blackList },
      messages: {
        required: 'Enter an email address',
        emailValidationRules: 'Enter a valid email address',
        isInBlackList: 'Your email is blocked',
      },
    },
  ];

  if (rowParams?.addConfirmation) {
    rules.push({
      name: names.EmailConfirmation,
      rules: { required, same: sameAs(names.Email) },
      messages: {
        required: 'Enter an email confirmation',
        same: 'Your email address does not match',
      },
    });
  }

  return new BaseValidation(rules, path);
};
