import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { Fragment } from 'vue-fragment';

import InputValidationMixin from '@/forms/FormElements/Mixins/InputValidationMixin';

import Tick from '@/assets/icons/tick-alt.svg?inline';

@Component({ components: { Tick, Fragment } })
export default class CheckInput extends mixins(InputValidationMixin) {
  @Prop(String)
  readonly id!: string;

  @Prop()
  readonly value!: any[] | any;

  @Prop({ type: Boolean, default: false })
  readonly checked?: Boolean;

  @Prop()
  readonly custom?: Boolean;

  @Prop()
  readonly checkValue!: any;

  get mappedToArray() {
    return Array.isArray(this.value);
  }

  get isChecked() {
    if (this.mappedToArray) {
      return this.value.includes(this.checkValue);
    }
    return this.value;
  }

  mounted() {
   if (this.custom) {
    this.$emit('input', this.checked);
   }
  }

  change(event: any) {
    const checked = event.target.checked;
    if (this.mappedToArray) {
      const newArray = [...this.value];
      const found = newArray.indexOf(this.checkValue);
      if (found !== -1) {
        newArray.splice(found, 1);
      } else {
        newArray.push(this.checkValue);
      }

      this.$emit('input', newArray);
    } else {
      this.$emit('input', checked);
    }
  }
}
