import BaseValidation from '@/forms/Validations/BaseValidation';
import NameValidation from '@/forms/FormElements/Rows/NameRow/NameRow.validation';
import EmailValidation from '@/forms/FormElements/Rows/EmailRow/EmailRow.validation';

import { names } from './EmailSignUpForm.types';

export default () => {
  const validations = [
    NameValidation('', { removeTitle: true }),
    EmailValidation(),
  ];

  return {
    validations: BaseValidation.getRules(validations),
    messages: BaseValidation.getMessages(validations),
    model: BaseValidation.getModel(validations),
  };
};
