export default interface Person {
  Id?: string;
  Title?: string;
  FirstName: string;
  LastName: string;
  Email?: string;
  EmailConfirmation?: string; //* service field (should not be in BE)
}

export type PersonFieldType = keyof Person;

export const names: { [key in PersonFieldType]: string } = {
  Id: '1',
  Title: 'Title',
  FirstName: 'FirstName',
  LastName: 'LastName',
  Email: 'Email',
  EmailConfirmation: 'EmailConfirmation',
};
