import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import TextRow from '@/forms/FormElements/Rows/TextRow/TextRow.vue';
import DropdownRow from '@/forms/FormElements/Rows/DropdownRow/DropdownRow.vue';

import RowValidationMixin from '@/forms/FormElements/Mixins/RowValidationMixin';

import { names } from './NameRow.validation';

@Component({ components: { TextRow, DropdownRow } })
export default class NameRow extends mixins(RowValidationMixin) {
  names = names;
  @Prop({ type: Number, default: 14 })
  readonly firstNameLength: number;
  @Prop({ type: Number, default: 18 })
  readonly lastNameLength: number;
  @Prop({ type: [String, Number], default: '' })
  readonly idPrefix!: string;

  get showTitle() {
    return this.model[names.Title] !== undefined;
  }

  titleList = [
    { Text: 'Mr.', Value: 'Mr.' },
    { Text: 'Mrs.', Value: 'Mrs.' },
    { Text: 'Miss.', Value: 'Miss.' },
    { Text: 'Ms.', Value: 'Ms.' },
    { Text: 'Dr.', Value: 'Dr.' },
  ];
}
