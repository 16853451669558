import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import FieldWrapper from '@/forms/FormElements/Common/FieldWrapper/FieldWrapper.vue';
import ErrorWrapper from '@/forms/FormElements/Common/ErrorWrapper/ErrorWrapper.vue';
import CheckInput from '@/forms/FormElements/Inputs/CheckInput/CheckInput.vue';

import RowValidationMixin from '@/forms/FormElements/Mixins/RowValidationMixin';
import SelectItem from '@/common/interfaces/SelectItem';

@Component({ components: { CheckInput, FieldWrapper, ErrorWrapper } })
export default class CheckBoxRow extends mixins(RowValidationMixin) {
  @Prop({ type: Array as () => SelectItem[] })
  readonly list!: SelectItem[];

  @Prop(Boolean)
  readonly getModelNamesFromList!: boolean;

  @Prop(Boolean)
  readonly displayAsColumn!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly noGutters!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hideLabel!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly checked?: boolean;

  @Prop()
  readonly custom?: Boolean;

  get options() {
    return this.list || [{ Text: this.label, Value: true }];
  }
}
